import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { ErrorBoundary } from '@preservica/react-components';
import { FluentProvider, Spinner, webLightTheme } from '@fluentui/react-components';
import { initializeIcons, PartialTheme, ThemeProvider } from '@fluentui/react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Protected } from '@preservica/auth';
import { Route, Routes } from 'react-router-dom';
import Constants from './components/Constants';
import Download from './pages/download/Download';
import Layout from './pages/layout/Layout';
import NotFoundPage from './pages/notfound/NotFound';
import React from 'react';
import Version from './pages/version/Version';
import ViewPage from './pages/view/View';

const appTheme: PartialTheme = {
  palette: {
    themePrimary: '#50378C',
  },
};

const unauthMessage = 'You must be logged in to view this page';

initializeIcons();

export interface IAppProps {
  msalInstance: IPublicClientApplication;
}

const LOG_SOURCE = 'App';
const App: React.FunctionComponent<IAppProps> = ({ msalInstance }: IAppProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={appTheme}>
        <FluentProvider theme={webLightTheme} style={{ height: '100%' }}>
          <AuthenticatedTemplate>
            <Routes>
              <Route element={<Layout pageFound={true} />}>
                <Route
                  path="/view/:preservicaId"
                  element={
                    <Protected
                      headerText="Preserve365"
                      unauthenticatedMessage={unauthMessage}
                      scope={Constants.MSAL_SCOPE}
                    >
                      <ErrorBoundary identifier="View Page">
                        <ViewPage />
                      </ErrorBoundary>
                    </Protected>
                  }
                />
                <Route
                  path="/download/:preservicaId"
                  element={
                    <Protected
                      headerText="Preserve365"
                      unauthenticatedMessage={unauthMessage}
                      scope={Constants.MSAL_SCOPE}
                    >
                      <ErrorBoundary identifier="Download Page">
                        <Download />
                      </ErrorBoundary>
                    </Protected>
                  }
                />
              </Route>
              <Route element={<Layout />}>
                <Route
                  path="/version"
                  element={
                    <ErrorBoundary identifier="Version Page">
                      <Version />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="*"
                  element={
                    <ErrorBoundary identifier="Not Found Page">
                      <NotFoundPage />
                    </ErrorBoundary>
                  }
                />
              </Route>
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Spinner
              label="Authenticating..."
              size="large"
              appearance="primary"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'inherit',
              }}
            />
          </UnauthenticatedTemplate>
        </FluentProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
