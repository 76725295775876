import { useCallback, useContext, useEffect } from "react";
import styles from "./View.module.css";
import { AppContext } from "../../state/appContext";
import { useParams } from "react-router-dom";
import { reset } from "../../state/appActions";
import { useAccount, useMsal } from "@azure/msal-react";
import { viewGetObjectDetails } from "../../components/common/authentication/appService";
import { Spinner, SpinnerSize } from "@fluentui/react";

const LOG_SOURCE = 'View';
const ViewPage: React.FunctionComponent = () => {
  const { preservicaId } = useParams();
  const { state, dispatch } = useContext(AppContext);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const viewAsset = useCallback(
    viewGetObjectDetails,
    [account, instance, preservicaId]
  );

  useEffect(() => {
    const accessTokenExpiryMins = 10;
    if (preservicaId) {
      if (preservicaId !== state.preservicaId) {
        viewAsset(dispatch, account, instance, preservicaId, LOG_SOURCE, 'viewAsset');
      } else if (state.assetTimestamp && Date.now() - new Date(state.assetTimestamp).getTime() > accessTokenExpiryMins*60*1000) {
        viewAsset(dispatch, account, instance, preservicaId, LOG_SOURCE, 'refreshAccessToken');
      }
    } else {
      dispatch(reset());
    }
  }, [viewAsset]);

  return state.asset ? 
    <div className={styles.View} data-qa-id='app-page-view'>
      {/* see https://preservica.atlassian.net/wiki/spaces/INNOV/pages/1034911747/Embed+Preservica+renderer+in+3rd+party+website */}
      <iframe height={window.innerHeight} width="100%" src={state.asset?.rendererUrl}></iframe>
    </div> :
    !state.error ? <Spinner
      size={SpinnerSize.large}
      label="Loading ..."
      labelPosition="left"
    /> : <></>
}

export default ViewPage;

