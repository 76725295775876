/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppConfigurationDto } from '../models/AppConfigurationDto';
import type { CorrelationId } from '../models/CorrelationId';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigurationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param xCorrelationId A GUID value used to correlate requests and responses
     * @returns AppConfigurationDto Success
     * @throws ApiError
     */
    public getConfiguration(
xCorrelationId: CorrelationId,
): CancelablePromise<AppConfigurationDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/configuration',
            headers: {
                'X-Correlation-ID': xCorrelationId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

}
