import styles from "./Title.module.css";
import { Body1, TextPresetProps, Title2, Title3 } from "@fluentui/react-components";
import { IFontStyles } from '@fluentui/react';
import { FunctionComponent } from "react";

export interface ITitleProps {
  title: string;
  variant?: keyof IFontStyles;
}

const Title: React.FunctionComponent<ITitleProps> = (props: ITitleProps) => {
  const Title = pickTitlePreset(props.variant);
  
  return (<div className={styles.Title} data-qa-id='app-title'>
    <Title
      aria-label={props.title}
      title={props.title}
      data-qa-id='app-title-text'
    >{props.title}</Title>
  </div>)
}

const pickTitlePreset = (variant?: keyof IFontStyles): FunctionComponent<TextPresetProps> => {
  let Title: FunctionComponent<TextPresetProps>;
  switch (variant) {
    case "medium":
      return Body1;
    case "xLarge":
      return Title3;
    default:
      return Title2;
  }
}

export default Title;
