import {
  DirectionalHint,
  IButtonStyles,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  IIconProps,
  ITooltipHostStyles,
  IconButton,
  TooltipDelay,
  TooltipHost,
  mergeStyles,
  useTheme,
} from "@fluentui/react";

export interface IChoiceItemProps {
  tooltip: string;
  props: (IChoiceGroupOption & IChoiceGroupOptionProps) | undefined;
  render: ((props?: (IChoiceGroupOption & IChoiceGroupOptionProps) | undefined) => JSX.Element | null) | undefined;
}

const ChoiceItem: React.FunctionComponent<IChoiceItemProps> = (props: IChoiceItemProps) => {
  const theme = useTheme();

  const infoIcon: IIconProps = { iconName: "info" };
  const optionRootClass = mergeStyles({ display: "flex", alignItems: "center", gap: "5px" });
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
  const iconButtonStyles: Partial<IButtonStyles> = {
    rootHovered: { backgroundColor: theme.palette.white },
    rootDisabled: { backgroundColor: theme.palette.white },
  };

  return (
    <div className={optionRootClass}>
      <TooltipHost
        delay={TooltipDelay.zero}
        content={props.tooltip}
        directionalHint={DirectionalHint.bottomCenter}
        styles={hostStyles}
      >
        <div className={optionRootClass}>
          {props.render?.(props.props)}
          {/* <Icon iconName="info" /> */}
          <IconButton styles={iconButtonStyles} iconProps={infoIcon} disabled={props.props?.disabled} />
        </div>
      </TooltipHost>
    </div>
  );
};

export default ChoiceItem;
