/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CorrelationId } from '../models/CorrelationId';
import type { ObjectDetails } from '../models/ObjectDetails';
import type { PreservicaId } from '../models/PreservicaId';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ViewService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param preservicaId 
     * @param xCorrelationId A GUID value used to correlate requests and responses
     * @returns ObjectDetails Success
     * @throws ApiError
     */
    public getObjectDetails(
preservicaId: PreservicaId,
xCorrelationId: CorrelationId,
): CancelablePromise<ObjectDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/view/{preservicaId}',
            path: {
                'preservicaId': preservicaId,
            },
            headers: {
                'X-Correlation-ID': xCorrelationId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                422: `UnprocessableEntity`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

}
