import { useContext, useMemo, FC } from "react";
import styles from "./BrowserPivot.module.css";
import { Pivot, PivotItem } from "@fluentui/react";
import Title from "../title/Title";
import { useNavigate, useParams } from "react-router-dom";
import { LogService } from "@preservica/log-service";
import { AppContext } from "../../../state/appContext";

const LOG_SOURCE = "BrowserPivot";
const DOWNLOAD_KEY = "Download";
const VIEW_KEY = "View";

const BrowserPivot: FC = () => {
  const { preservicaId } = useParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const selectedPivot = useMemo((): string => {
    const selectedPivotKey = window.location.pathname
      .toLocaleLowerCase()
      .startsWith(`/${DOWNLOAD_KEY.toLocaleLowerCase()}/`)
      ? DOWNLOAD_KEY
      : VIEW_KEY;
    LogService.info(LOG_SOURCE, "selectedPivotKey", selectedPivotKey);
    return selectedPivotKey;
  }, [window.location.pathname]);

  const handleLinkClick = (item?: PivotItem): void => {
    const LOG_MESSAGE = "handleLinkClick";
    LogService.verbose(LOG_SOURCE, LOG_MESSAGE, "item", item);
    if (item && item.props && item.props.itemKey !== selectedPivot) {
      let href;
      if (item.props.itemKey === DOWNLOAD_KEY) {
        href = `/${DOWNLOAD_KEY.toLocaleLowerCase()}/${preservicaId}`;
      } else {
        href = `/${VIEW_KEY.toLocaleLowerCase()}/${preservicaId}`;
      }
      LogService.info(LOG_SOURCE, LOG_MESSAGE, "href", href);
      navigate(href);
    }
  };

  // Show if download enabled, or if the user has navigated to the download page URL - this is so the error message displays within the correct tab
  const showDownloadTab =
    state.appConfiguration?.allowDownload || selectedPivot === DOWNLOAD_KEY;

  return (
    <div className={styles.Pivot} data-qa-id="app-pivot">
      <Pivot onLinkClick={handleLinkClick} selectedKey={selectedPivot}>
        <PivotItem
          headerText={VIEW_KEY}
          ariaLabel={VIEW_KEY}
          itemKey={VIEW_KEY}
        >
          <Title title={VIEW_KEY} variant={"xLarge"} />
        </PivotItem>
        {showDownloadTab && (
          <PivotItem
            headerText={DOWNLOAD_KEY}
            ariaLabel={DOWNLOAD_KEY}
            itemKey={DOWNLOAD_KEY}
          >
            <Title title={DOWNLOAD_KEY} variant={"xLarge"} />
          </PivotItem>
        )}
      </Pivot>
    </div>
  );
};

export default BrowserPivot;
