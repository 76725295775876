/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Health } from '../models/Health';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HealthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns the health status of the API
     * @returns Health OK
     * @throws ApiError
     */
    public getHealth(): CancelablePromise<Health> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health/status',
            errors: {
                404: `NotFound`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

}
