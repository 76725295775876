import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { BrowserCacheLocation, EventType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '@preservica/auth';
import PreBuildConstants from './components/PreBuildConstants';
import Constants from './components/Constants';
import AppSettings from './components/AppSettings';

const msalInstance = new PublicClientApplication({
  ...msalConfig(AppSettings.AuthAppId),
  cache: { cacheLocation: BrowserCacheLocation.SessionStorage },
});

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Silent SSO
msalInstance.handleRedirectPromise().then((event: any) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    msalInstance.loginRedirect(loginRequest(AppSettings.AuthAppId, Constants.MSAL_SCOPE));
  }
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <BrowserRouter>
    <App msalInstance={msalInstance} />
  </BrowserRouter>,
  document.getElementById('root') as HTMLElement
);

reportWebVitals();
