/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ConfigurationService } from './services/ConfigurationService';
import { DownloadService } from './services/DownloadService';
import { HealthService } from './services/HealthService';
import { VersionService } from './services/VersionService';
import { ViewService } from './services/ViewService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly configuration: ConfigurationService;
    public readonly download: DownloadService;
    public readonly health: HealthService;
    public readonly version: VersionService;
    public readonly view: ViewService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/api',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.configuration = new ConfigurationService(this.request);
        this.download = new DownloadService(this.request);
        this.health = new HealthService(this.request);
        this.version = new VersionService(this.request);
        this.view = new ViewService(this.request);
    }
}
