/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentType } from '../models/ContentType';
import type { CorrelationId } from '../models/CorrelationId';
import type { DownloadContents } from '../models/DownloadContents';
import type { FileInfo } from '../models/FileInfo';
import type { PreservicaId } from '../models/PreservicaId';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DownloadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param preservicaId 
     * @param xCorrelationId A GUID value used to correlate requests and responses
     * @returns DownloadContents Success
     * @throws ApiError
     */
    public getDownloadContents(
preservicaId: PreservicaId,
xCorrelationId: CorrelationId,
): CancelablePromise<DownloadContents> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/download/{preservicaId}',
            path: {
                'preservicaId': preservicaId,
            },
            headers: {
                'X-Correlation-ID': xCorrelationId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                422: `UnprocessableEntity`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

    /**
     * @param preservicaId 
     * @param contentId 
     * @param contentType 
     * @param xCorrelationId A GUID value used to correlate requests and responses
     * @returns FileInfo Success
     * @throws ApiError
     */
    public getContent(
preservicaId: PreservicaId,
contentId: string,
contentType: ContentType,
xCorrelationId: CorrelationId,
): CancelablePromise<FileInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/download/{preservicaId}/{contentId}/{contentType}',
            path: {
                'preservicaId': preservicaId,
                'contentId': contentId,
                'contentType': contentType,
            },
            headers: {
                'X-Correlation-ID': xCorrelationId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                422: `UnprocessableEntity`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

    /**
     * @param preservicaId 
     * @param contentType 
     * @param xCorrelationId A GUID value used to correlate requests and responses
     * @param includePropertiesFile 
     * @param requestBody 
     * @returns FileInfo Success
     * @throws ApiError
     */
    public downloadContentsZip(
preservicaId: PreservicaId,
contentType: ContentType,
xCorrelationId: CorrelationId,
includePropertiesFile?: boolean,
requestBody?: Array<string>,
): CancelablePromise<FileInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/download/{preservicaId}/{contentType}',
            path: {
                'preservicaId': preservicaId,
                'contentType': contentType,
            },
            headers: {
                'X-Correlation-ID': xCorrelationId,
            },
            query: {
                'includePropertiesFile': includePropertiesFile,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                422: `UnprocessableEntity`,
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

}
