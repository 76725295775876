import styles from "./Description.module.css";
import { Body2 } from '@fluentui/react-components';

export interface IDescriptionProps {
  description: string;
}

const Description: React.FunctionComponent<IDescriptionProps> = (props: IDescriptionProps) => {

  return (<div className={styles.Description} data-qa-id='app-description'>
    <Body2
      aria-label={props.description}
      title={props.description}
      block
      data-qa-id='app-description-text'
    >{props.description}</Body2>
  </div>)
}

export default Description;
