import React from "react";
import { IAppState, initialAppState } from "./appState";
import { AppActions } from "./appActions";
import { AppClient } from "../generated";

export const AppContext = React.createContext<{
  state: IAppState;
  dispatch: React.Dispatch<AppActions>;
}>({
  state: initialAppState,
  dispatch: () => undefined,
});