/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Version } from '../models/Version';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VersionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns the build version information of the API
     * @returns Version OK
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<Version> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
            errors: {
                500: `InternalServerError`,
                503: `ServiceUnavailable`,
            },
        });
    }

}
