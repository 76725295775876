import React from "react";
import styles from "./Header.module.css";
import Description from "../description/Description";
import Title from "../title/Title";
import BrowserPivot from "../browserpivot/BrowserPivot";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { AppContext } from "../../../state/appContext";
import { ApiReason } from "@preservica/react-components";

const Header: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(AppContext);

  const showError = (error: any) => {
    return (
      <>
        {/* IIFE */}
        {(() => {
          if (error.apiReason && (error.apiReason.status == 401 || error.apiReason.status == 403)) {
            return (
              <div className={styles.accessDeniedView} data-testid="access-denied-view">
                <Title title="Access denied" />
                <Description description="You don't have access to view this page. Contact your administrator for further information." />
              </div>
            );
          } else {
            return (
              <MessageBar data-qa-id="app-header-error-message" messageBarType={MessageBarType.severeWarning}>
                <span data-qa-id="app-header-error-message-generic">{state.error?.message}</span>
                <ApiReason reason={state.error?.apiReason} />
              </MessageBar>
            );
          }
        })()}
      </>
    );
  };

  return (
    <div className={styles.Header} data-qa-id="app-header">
      {state.asset && (
        <>
          <Title title={state.asset.title} />
          <Description description={state.asset.description} />
          <BrowserPivot />
        </>
      )}
      {state.error && showError(state.error)}
    </div>
  );
};

export default Header;
