// export enum ViewType {
//   Access = "Access",
//   Latest = "Latest",
//   Original = "Original"
// }

// should match the generated ContentType enum
export enum ViewType {
  "Access" = 0,
  "Latest" = 1,
  "Original" = 2
}