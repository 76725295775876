import React from "react";
import Description from "../../components/layout/description/Description";
import Title from "../../components/layout/title/Title";
import styles from "./Version.module.css";
import { AppClient } from "../../generated";
import PreBuildConstants from "../../components/PreBuildConstants";
import Constants from "../../components/Constants";
import { LogService } from "@preservica/log-service";

const LOG_SOURCE = "VersionPage";
const VersionPage: React.FunctionComponent = () => {
  const [apiVersion, setApiVersion] = React.useState<string>("");

  React.useEffect(() => {
    const LOG_MESSAGE = "getVersion";
    LogService.verbose(LOG_SOURCE, LOG_MESSAGE);
    const appClient: AppClient = new AppClient({
      BASE: Constants.CLIENT_API_BASE_URL,
    });
    appClient.version.getVersion().then(
      (value) => {
        // Success
        LogService.info(LOG_SOURCE, LOG_MESSAGE, "value", value);
        setApiVersion(value.buildNumber ? value.buildNumber : "");
      },
      (reason) => {
        // Fail
        LogService.error(LOG_SOURCE, LOG_MESSAGE, "Error", reason);
        setApiVersion("");
      }
    );
  }, []);

  return (
    <div className={styles.Version} data-qa-id="app-page-version">
      <Title title="Version" />
      <Description description={`Client Version: ${PreBuildConstants.CLIENT_VERSION}`} />
      {apiVersion && <Description description={`Api Version: ${apiVersion}`} />}
    </div>
  );
};

export default VersionPage;
