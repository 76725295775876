import {
  DefaultButton,
  Dropdown,
  IButtonStyles,
  IDropdownOption,
  IDropdownStyles,
  ILabelStyles,
  IStackTokens,
  ITextFieldStyles,
  Label,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React from "react";
import { useState } from "react";
import { Config } from "../../../config";

export interface IPaginationProps {
  currentPage: number;
  pagesTotal: number;
  numberOfAttachments: number;
  navigateTo: (pageNumber: number) => void;
  updatePageSize: (pageSize: number) => void;
}

const Pagination: React.FunctionComponent<IPaginationProps> = (props: IPaginationProps) => {
  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 0,
  };

  const [goPage, updateGoPage] = useState<number>(0);

  const groupSize = 3;
  const boxSize = 32;
  const prevNextBoxWidth = 64;
  const goBoxWidth = 48;
  const dropdownBoxWidth = 64;

  const buttonStyles: IButtonStyles = {
    root: {
      width: boxSize,
      height: boxSize,
      padding: 0,
      margin: 0,
      minWidth: boxSize,
    },
  };

  const prevNextButtonStyles: IButtonStyles = {
    root: {
      width: prevNextBoxWidth,
      height: boxSize,
      padding: 0,
      margin: 0,
      minWidth: prevNextBoxWidth,
    },
    label: {
      fontWeight: 700,
    },
  };

  const textStyles: Partial<ITextFieldStyles> = {
    root: {
      width: goBoxWidth,
      height: boxSize,
      marginLeft: 20,
    },
  };

  const labelStyles: Partial<ILabelStyles> = {
    root: {
      width: goBoxWidth,
      height: boxSize,
      marginLeft: 20,
      fontWeight: 700,
    },
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: dropdownBoxWidth },
    dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
    dropdownItem: { height: "auto" },
  };

  const isValidPage = (page: number): boolean => {
    return !isNaN(page) && page >= 1 && page <= props.pagesTotal;
  };

  const onChangeGoPage = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      const navigateTo: number = newValue ? parseInt(newValue) : 0;
      updateGoPage(isValidPage(navigateTo) ? navigateTo : 0);
    },
    []
  );

  const getPageLinks = (pageStart: number, pageEnd: number, currentPage: number): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    if (pageEnd >= pageStart) {
      [...Array(pageEnd - pageStart + 1).keys()].map((i) => elements.push(getPageLink(i + pageStart, currentPage)));
    }
    return elements;
  };

  const getPageLink = (page: number, currentPage: number): JSX.Element => {
    return page === currentPage ? (
      <PrimaryButton
        text={page.toString()}
        key={page.toString()}
        styles={buttonStyles}
        onClick={() => props.navigateTo(page)}
      ></PrimaryButton>
    ) : (
      <DefaultButton
        text={page.toString()}
        key={page.toString()}
        styles={buttonStyles}
        onClick={() => props.navigateTo(page)}
      ></DefaultButton>
    );
  };

  const addPrevNextLink = (label: string, navigateIncrement: number, currentPage: number): JSX.Element => {
    const isDisabled =
      (navigateIncrement === -1 && currentPage === 1) || (navigateIncrement === 1 && currentPage === props.pagesTotal);
    return (
      <DefaultButton
        text={label}
        key={label}
        disabled={isDisabled}
        styles={prevNextButtonStyles}
        onClick={() => props.navigateTo(props.currentPage + navigateIncrement)}
      ></DefaultButton>
    );
  };

  const addSpacer = (key: string): JSX.Element => {
    return <DefaultButton text={Config.Pagination.SpacerLabel} key={key} styles={buttonStyles}></DefaultButton>;
  };

  const addPageLinks = (): JSX.Element => {
    return (
      <>
        {/* IIFE */}
        {(() => {
          const output: JSX.Element[] = [];

          if (props.pagesTotal <= 2 * groupSize + 1) {
            output.push(...getPageLinks(1, props.pagesTotal, props.currentPage));
          } else {
            const smallGroupSize = (groupSize - 1) / 2;
            const bigGroupSize = groupSize * 2 - 1;

            let firstGroupSize, lastGroupSize;
            if (props.currentPage < bigGroupSize) {
              // current is in first group - show large first
              firstGroupSize = bigGroupSize;
              lastGroupSize = smallGroupSize;
            } else if (props.currentPage > props.pagesTotal - (bigGroupSize - 1)) {
              // current is in last group - show large last
              firstGroupSize = smallGroupSize;
              lastGroupSize = bigGroupSize;
            } else {
              // current is in middle
              firstGroupSize = lastGroupSize = smallGroupSize;
            }

            // First group
            output.push(...getPageLinks(1, firstGroupSize, props.currentPage));

            // current group if current page is not in the first group
            let currentGroupStart = Math.max(
              Math.min(props.currentPage - smallGroupSize, props.pagesTotal - (lastGroupSize - 1)),
              firstGroupSize + 1
            ); // Behave correctly when current is at start or end
            const currentGroupEnd = Math.min(props.currentPage + smallGroupSize, props.pagesTotal);
            if (currentGroupStart === firstGroupSize + 2) {
              currentGroupStart--;
            } // don't ... a single cell
            if (currentGroupStart > firstGroupSize + 1) {
              output.push(addSpacer("spacerFirst"));
            }
            output.push(...getPageLinks(currentGroupStart, currentGroupEnd, props.currentPage));

            // last group starts after last item
            const prevGroupEnd = Math.max(currentGroupEnd, firstGroupSize);
            let lastGroupStart = Math.max(props.pagesTotal - (lastGroupSize - 1), prevGroupEnd + 1);
            if (lastGroupStart === prevGroupEnd + 2) {
              lastGroupStart--;
            } // don't ... a single cell
            if (lastGroupStart > prevGroupEnd + 1) {
              output.push(addSpacer("spacerLast"));
            }
            output.push(...getPageLinks(lastGroupStart, props.pagesTotal, props.currentPage));
          }

          return <>{output}</>;
        })()}
      </>
    );
  };

  const onChangeNumberOfAttachments = (item?: IDropdownOption): void => {
    item && props.updatePageSize(item.key as number);
  };

  return (
    <Stack horizontal tokens={horizontalGapStackTokens}>
      {addPrevNextLink(Config.Pagination.PrevLabel, -1, props.currentPage)}
      {addPageLinks()}
      {addPrevNextLink(Config.Pagination.NextLabel, +1, props.currentPage)}
      <TextField styles={textStyles} onChange={onChangeGoPage}></TextField>
      <DefaultButton
        text={Config.Pagination.GoLabel}
        styles={prevNextButtonStyles}
        onClick={() => isValidPage(goPage) && props.navigateTo(goPage)}
      ></DefaultButton>
      <Label styles={labelStyles}>Show</Label>
      <Dropdown
        styles={dropdownStyles}
        selectedKey={props.numberOfAttachments}
        onChange={(event, option) => onChangeNumberOfAttachments(option)}
        options={Config.Pagination.AttachmentsOptions}
      />
    </Stack>
  );
};

export default Pagination;
