/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Health = {
    status?: Health.status;
};

export namespace Health {

    export enum status {
        HEALTHY = 'Healthy',
        DEGRADED = 'Degraded',
        UNHEALTHY = 'Unhealthy',
    }


}
