export const Config = {
  ShowSelectAllIfMoreItemsThan: 5,
  DownloadChoices: {
    Access: {
      Title: "Latest access",
      Description: "Download the latest access-only copy of any selected content."
    },
    Latest: {
      Title: "Latest preservation master",
      Description: "Download the most recent file format preservation master of any selected content."
    },
    Original: {
      Title: "Original",
      Description: "Download the original version of any selected content, in its original file format."
    }
  },
  PropertiesFileCheckboxText: {
    Default: "Include properties file",
    WhenNoOtherContent: "Properties file"
  },
  Pagination: {
    PrevLabel: "< Prev",
    NextLabel: "Next >",
    GoLabel: "Go >",
    SpacerLabel: "...",
    AttachmentsDefault: 10,
    AttachmentsOptions: [
      { key: 10, text: '10' },
      { key: 15, text: '15' },
      { key: 20, text: '20' }
    ]
  }
}