import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import logo from "../../logo.svg";
import {
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  useTheme,
} from "@fluentui/react";
import Header from "../../components/layout/header/Header";
import { appReducer } from "../../state/appReducer";
import { initialAppState } from "../../state/appState";
import { AppContext } from "../../state/appContext";
import { ErrorBoundary } from "@preservica/react-components";
import { useEffect, useReducer } from "react";
import { SignInButton, SignOutButton } from "@preservica/auth";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import Constants from "../../components/Constants";
import { makeStyles } from "@fluentui/react-components";
import { getAppConfiguration } from "../../components/common/authentication/appService";
const useButtonStyles = makeStyles({
  buttonStyles: {},
});

export interface ILayoutProps {
  pageFound?: boolean;
}

const LOG_SOURCE = "Layout";

const Layout: React.FunctionComponent<ILayoutProps> = (props: ILayoutProps) => {
  const theme = useTheme();
  const buttonStyles = useButtonStyles();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [state, dispatch] = useReducer(appReducer, initialAppState);

  useEffect(() => {
    getAppConfiguration(
      dispatch,
      account,
      instance,
      LOG_SOURCE,
      "getAppConfiguration"
    );
  }, []);

  const showLoading = !state.appConfiguration && !state.error;

  return (
    <div className={styles.App} data-qa-id="app-page-layout">
      <AppContext.Provider value={{ state, dispatch }}>
        <ErrorBoundary identifier="layout">
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={styles.AppHeader}
            style={{ backgroundColor: theme.palette.themePrimary }}
          >
            <Stack horizontal>
              <StackItem>
                <img src={logo} className={styles.AppLogo} alt="logo" />
              </StackItem>
              <StackItem className={styles.AppHeaderText}>
                Preserve365
              </StackItem>
            </Stack>
            <Stack horizontal>
              <AuthenticatedTemplate>
                <StackItem className={styles.AppHeaderText}>
                  {account?.name}
                </StackItem>
                <StackItem align="center">
                  <SignOutButton
                    clientAppInstance={instance}
                    scope={Constants.MSAL_SCOPE}
                    buttonStyles={buttonStyles.buttonStyles}
                  />
                </StackItem>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <StackItem align="center">
                  <SignInButton
                    clientAppInstance={instance}
                    scope={Constants.MSAL_SCOPE}
                    buttonStyles={buttonStyles.buttonStyles}
                  />
                </StackItem>
              </UnauthenticatedTemplate>
            </Stack>
          </Stack>
          <div className={styles.AppBody} data-qa-id="app-body">
            <div className={styles.AppMain} data-qa-id="app-main">
              <AuthenticatedTemplate>
                {props.pageFound && (
                  <ErrorBoundary identifier="Header">
                    <Header />
                  </ErrorBoundary>
                )}
              </AuthenticatedTemplate>
              {showLoading ? (
                <Spinner
                  size={SpinnerSize.large}
                  label="Loading ..."
                  labelPosition="left"
                />
              ) : state.appConfiguration ? (
                <Outlet />
              ) : (
                <></>
              )}
            </div>
          </div>
        </ErrorBoundary>
      </AppContext.Provider>
    </div>
  );
};

export default Layout;
