import { Body2 } from "@fluentui/react-components";
import { FC } from "react";
import styles from "./DownloadDisabledMessage.module.css";

const DownloadDisabledMessage: FC = () => {
  const downloadDisabledMessage =
    "Asset download is disabled for this tenant. Contact your administrator for further information.";

  return (
    <Body2
      aria-label={downloadDisabledMessage}
      title={downloadDisabledMessage}
      block
      data-qa-id="app-download-disabled-text"
      className={styles.downloadDisabledMessage}
    >
      {downloadDisabledMessage}
    </Body2>
  );
};

export default DownloadDisabledMessage;
