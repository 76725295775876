import { useState } from "react";
import Title from "../title/Title";
import { Checkbox, ICheckboxStyles, ISpinnerStyles, IStackItemStyles, IStackStyles, IStackTokens, Link, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import React from "react";
import { Config } from "../../../config";
import Pagination from "../pagination/pagination";

export interface IComponent {
  id: string;
  title: string;
}

export interface IComponentsListProps {
  loading: boolean;
  displayItems: IComponent[];
  itemsTotal: number;
  displayItemsSelectAllLimit?: number;
  pageNumber: number;
  pagesTotal: number;
  pageSize: number;
  allComponentsSelected: boolean;
  selectedComponents: IComponent[];
  singleItemDownloadUrlProvider: (contentId: string) => string;
  downloadItem: (component: IComponent) => void;
  downloadSelectedItems: (includePropertiesFile: boolean) => void;
  navigateToPage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
  selectComponent: (component: IComponent) => void;
  selectAllComponents: () => void;
}

const ComponentsList: React.FunctionComponent<IComponentsListProps> = (props: IComponentsListProps) => {
  const [selectPropertiesFile, updateSelectPropertiesFile] = useState<boolean>(false);
  const [propertiesFileCheckboxText, updatePropertiesFileCheckboxText] = useState<string>(Config.PropertiesFileCheckboxText.Default);

  // only show 'Select All' if there are more items than this
  const itemsSelectAllLimit: number = props.displayItemsSelectAllLimit ?? Config.ShowSelectAllIfMoreItemsThan;

  const spinnerStyles: ISpinnerStyles = {
    root: {
      marginTop: 50,
      marginBottom: 50,
      justifyContent: 'left'
    }
  };

  const stackTopStyles: IStackStyles = {
    root: {
      paddingTop: 10
    }
  };
  
  const stackPaginationStyles: IStackStyles = {
    root: {
      paddingTop: 20
    }
  };
  
  const stackBottomStyles: IStackStyles = {
    root: {
      paddingBottom: 20
    }
  };
  
  const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10
  };
  
  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 5
  };

  const finalPagePaddingItemStyles: IStackItemStyles = {
    root: {
      paddingTop: 1
    }
  };

  const boldCheckboxStyles: ICheckboxStyles = {
    label: {
      fontWeight: 700
    }
  };

  const isDownloadButtonDisabled = (): boolean => {
    return !selectPropertiesFile && props.selectedComponents.length === 0;
  }
  
  // handle when display items changes (i.e. radio button is toggled)
  React.useEffect(() => {
    // set properties file checkbox text & selected state
    const noOtherContent: boolean = props.displayItems.length === 0;
    updateSelectPropertiesFile(noOtherContent);
    updatePropertiesFileCheckboxText(noOtherContent ?
      Config.PropertiesFileCheckboxText.WhenNoOtherContent :
      Config.PropertiesFileCheckboxText.Default
    );
  }, [props.displayItems]);
  
  return <>
    <Title title="Content" variant={'xLarge'} />

    {!props.loading && <>
      <Stack styles={stackTopStyles}>      
        {props.itemsTotal > itemsSelectAllLimit && <>
            <Stack horizontal>
              <Checkbox styles={boldCheckboxStyles} label={`Select all (selected ${props.selectedComponents.length} of ${props.itemsTotal})`}
                checked={props.allComponentsSelected}
                onChange={() => props.selectAllComponents()}
              />
              {/* <Label styles={labelStyles}>(selected {props.selectedComponents.length} of {props.itemsTotal})</Label> */}
            </Stack>
            <Separator></Separator>
          </>
        }
      </Stack>

      <Stack tokens={verticalGapStackTokens}>
        {props.displayItems.map((item) =>
          <Stack horizontal key={item.title} tokens={horizontalGapStackTokens}>
            <StackItem>
              <Checkbox
                checked={props.selectedComponents.map(i => i.title).includes(item.title)} // match on title only
                onChange={() => props.selectComponent(item)} />
              </StackItem>
            <StackItem>
              {/* this approach handles the request in code by submitting a request to the endpoint */}
              <Link onClick={() => props.downloadItem(item)} underline>
                {`${item.title}`}
              </Link>
              {/* this approach creates a link and lets the browser handle it */}
              {/* we need to use the above approach with a secure enspoint so that we can submit an access token with the page request */}
              {/* <Link href={props.singleItemDownloadUrlProvider(item.id)} target="_blank" underline>
                {`${item.title}`}
              </Link> */}
            </StackItem>
          </Stack>
        )}
        {/* add padding at the bottom so the final page takes up the same space even if it isn't full */}
        {props.pagesTotal > 1 && [...Array(props.pageSize - props.displayItems.length).keys()].map((index) => 
          <Stack key={index}>
            <StackItem styles={finalPagePaddingItemStyles}>&nbsp;</StackItem>
          </Stack>
        )}
      </Stack>

      {props.pagesTotal > 1 && <Stack styles={stackPaginationStyles}>
        <Pagination
          currentPage={props.pageNumber}
          pagesTotal={props.pagesTotal}
          numberOfAttachments={props.pageSize}
          navigateTo={props.navigateToPage}
          updatePageSize={props.changePageSize}
        />
      </Stack>}
    
      <Stack styles={stackBottomStyles}>
        <Separator></Separator>
        <Checkbox styles={boldCheckboxStyles} label={propertiesFileCheckboxText}
          checked={selectPropertiesFile}
          onChange={() => updateSelectPropertiesFile(!selectPropertiesFile)}
        />
      </Stack>

      <PrimaryButton text="Download"
        disabled={isDownloadButtonDisabled()}
        onClick={() => props.downloadSelectedItems(selectPropertiesFile)}
      />
    </>}

    {props.loading && <Spinner
      size={SpinnerSize.large}
      styles={spinnerStyles}
      label="Loading ..."
      labelPosition="left"
    />}
  </>
}

export default ComponentsList;